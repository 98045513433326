import React, { useEffect } from 'react';
import { IDinosaur } from '../utilities/types';

import herbivoreIcon from '../assets/icons/herbivore-icon.png';
import carnivoreIcon from '../assets/icons/carnivore-icon.png';
import dinosaurWeight from '../assets/dinosaur-weight.png';
import dinosaurEra from '../assets/dinosaur-era.png';
import dinosaurLocation from '../assets/dinosaur-location.png';
import dinosaurType from '../assets/dinosaur-type.png';

import Card from '../components/Card/Card';

type DinoDataScreenProps = {
	dinoData: IDinosaur;
}

const fakeUserData = {
	name: 'John Doe',
	profileImgSrc: 'http://placekitten.com/300/300',
	dailyDino: {
		caught: 84,
		completed: 47,
		ids: [
			1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
			12, 13, 14, 15, 16, 17, 18, 19,
			20, 21, 22, 24, 25, 26, 27, 28,
			29, 30, 31, 32, 33, 34, 35, 36,
			37, 38, 39, 40, 41, 42, 43, 44,
			45, 46, 47, 48, 49, 50, 51, 52,
			53, 54, 55, 56, 57, 58, 59, 60,
			61, 62, 63, 64, 65, 66, 67, 68,
			69, 70, 71, 72, 73, 74, 75, 76,
			77, 78, 79, 80, 81, 82, 83, 84
		],
	}
}

const DinoDataScreen: React.FC<DinoDataScreenProps> = ({dinoData}) => {
	const [era, setEra] = React.useState<string[]>([]);

	useEffect(() => {
		// Split the era by comma
		if (dinoData && dinoData.era) {
			const era = dinoData.era.split(',');
			setEra(era);
		// Replace the HTML tags in the description
		if (dinoData.descriptions && dinoData.descriptions[0]) {
			dinoData.descriptions[0].forEach((description, index) => {
				if (dinoData.descriptions) {
					(dinoData.descriptions[0][index] as string) = description.replace(/(<([^>]+)>)/ig, '').trim();
				}
			})
			console.log(`replaced tags - ${dinoData.descriptions && dinoData.descriptions[0]}`)
			// Filter out empty descriptions
			dinoData.descriptions[0].forEach((description, index) => {
				if (dinoData.descriptions) {
					if (dinoData.descriptions[0][index] === '') {
						dinoData.descriptions[0].splice(index, 1);
					}
				}
			})
		}
		}
	}, [dinoData]);

	return (
		<div className='absolute bottom-0 top-0 left-0 right-0 overflow-x-scroll bg-cream p-4 pb-0' >
				<h1 className='text-3xl text-center my-4'>{dinoData && dinoData.name.unhyphenated}</h1>
				<div className='flex items-center w-full justify-around mb-4' >
					<div className='bg-brown rounded-md p-1'>
						{dinoData && dinoData.images && <img className='max-h-96 rounded-md' src={`${process.env.REACT_APP_SERVER_URL}/dinodex/silhouettes/${dinoData.images[0].src}`} alt={dinoData.images[0].alt} />}
					</div>
				</div>
				<div className='flex justify-around items-center w-full flex-col mb-4'>
					<p className='text-lg text-center'>{dinoData && dinoData.name.pronunciation}</p>
					<p className='text-md text-center text-l-brown'>{dinoData && dinoData.name.meaning}</p>
				</div>
				{(dinoData && dinoData.diet) &&	<Card bgClass={dinoData && dinoData.diet === 'carnivorous' ? 'bg-meat-red' : dinoData && dinoData.diet === 'omnivorous' ? 'bg-meat-red' : 'bg-l-green'} content={
					<div className='flex flex-col justify-around mx-2 my-4'>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>Diet</h2>
						<div className='w-full flex justify-center items-center' >
							{dinoData && dinoData.diet === 'carnivorous' ? <img className='w-10 h-10 mr-4' src={carnivoreIcon} alt="Carnivore" /> : dinoData && dinoData.diet === 'omnivorous' ? <div className='flex' ><img className='w-10 h-10 mr-4' src={herbivoreIcon} alt="Herbivore" /><img className='w-10 h-10 mr-4' src={carnivoreIcon} alt="Carnivore" /></div> : <img className='w-10 h-10 mr-4' src={herbivoreIcon} alt="Herbivore" />}
							{dinoData && dinoData.diet === 'carnivorous' ? <p className='text-cream text-xl text-center'>Carnivore</p> : dinoData && dinoData.diet === 'omnivorous' ? <p className='text-cream text-xl text-center'>Omnivorous</p> : <p className='text-cream text-xl text-center'>Herbivore</p>}
						</div>
					</div>
				} /> }
				{(dinoData && (dinoData.length || dinoData.weight)) && <Card bgClass='bg-earth-gray' content={
					<div className='flex justify-around items-center w-full mx-2 my-4 flex-col'>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>How big was it</h2>
						<div className='flex w-full'>
							<img className='w-3/5' src={dinosaurWeight} alt="Dinosaur Measurements" />
							<div className='flex flex-col justify-center items-center w-2/5'>
								{dinoData.length && <>
									<p className='text-cream text-lg font-bold text-center'>Length</p>
									<p className='text-cream text-2xl text-center mb-2'>{dinoData && dinoData.length}</p>
								</>}
								{dinoData.weight && <>
									<p className='text-cream text-lg font-bold text-center'>Weight</p>
									<p className='text-cream text-2xl text-center'>{dinoData && dinoData.weight}</p>
								</>}
							</div>
						</div>
					</div>
				} />}
				{(era[0] || era[1]) && <Card bgClass='bg-d-green' content={
					<div className='flex flex-col w-full mx-2 my-4'>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>When it lived</h2>
						<div className='flex flex-col w-full'>
							<img className='w-4/5 self-center mb-1' src={dinosaurEra} alt="Dinosaur Era" />
							<div className='flex flex-col mx-2'>
								{era[0] && <p className='text-cream text-lg font-bold'>{era[0]}</p>}
								{era[1] && <p className='text-cream text-xl'>{era[1]}</p>}
							</div>
						</div>
					</div>
				} />}
				<Card bgClass='bg-brown' content={
					<div className='flex flex-col w-full mx-2 my-4'>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>Found in</h2>
						<div className='flex flex-col w-full'>
							<img className='w-4/5 self-center mb-2' src={dinosaurLocation} alt="Dinosaur Location" />
							<p className='text-cream text-xl text-center font-bold'>{dinoData && dinoData.location}</p>
						</div>
					</div>
				} />
				<Card bgClass='bg-l-blue' content={
					<div className='flex flex-col w-full mx-2 my-4'>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>Type of dinosaur</h2>
						<div className='flex w-full'>
							<img className='w-2/5' src={dinosaurType} alt="Dinosaur Type" />
							<p className='w-3/5 text-cream text-xl text-center self-center'>{dinoData && dinoData.type}</p>
						</div>
					</div>
				} />
				{(dinoData.descriptions && dinoData.descriptions[0]) && dinoData.descriptions[0].length >= 1 && <Card className={`flex-col px-4 py-4`} bgClass='bg-orange' content={
					<>
						<h2 className='text-cream mb-4 text-xl text-center font-bold w-full'>Additional Info</h2>
						<div className='flex flex-col w-full'>
							{dinoData.descriptions[0].map((description, index) => {
								return <p key={index} className='text-cream text-md'>- {description}</p>
							})}
						</div>
					
					</>
				} />}

				{/* <Card bgClass='bg-purple' content={
					<div className='flex p-4 w-full'>
						<div className='w-1/3 rounded-full p-1 bg-cream'>
							<img className='w-full h-full rounded-full' src={fakeUserData.profileImgSrc} alt='profile' />
						</div>
						<div className='flex flex-col justify-center items-center w-2/3'>
							<p className='text-lg text-cream'>Dinosaurs</p>
							<div className='flex justify-around text-cream w-full px-4'>
								<p className='text-3xl flex'><span className='text-transparent'>%</span>{fakeUserData.dailyDino.caught}</p>
								<p className='text-3xl'>|</p>
								<p className='text-3xl'>{fakeUserData.dailyDino.completed}%</p>
							</div>
							<p className='text-lg text-cream'>Collected</p>
						</div>
					</div>
				} /> */}
			</div>
	);
}

export default DinoDataScreen;
