import React from 'react';
import loadingSpinnerImage from "../assets/LoadingSpinner.png"

const LoadingScreen: React.FC = () => {
	return (
		<div className="w-full h-full flex flex-col justify-center items-center loading-bg bg-cover">
			<h1 className='text-6xl font-bold pb-8 text-white'>Daily Dino</h1>
			<img className="animate-spin-slow w-52 h-52" src={loadingSpinnerImage} alt='A dinosaur head in a cream circle spinning as a loading spinner.'/>
			<h3 className='animate-pulse text-3xl font-bold pt-8 text-white'>Loading...</h3>
		</div>
	);
}

export default LoadingScreen;
