import React, { useState, useRef } from 'react';

type ErrorScreenProps = {
	error: string;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {

	return (
		<div className="w-full h-full flex justify-center items-center">
			<p>{"Server Error: " + error}</p>
		</div>
	);
}

export default ErrorScreen;
