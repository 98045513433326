import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IDinosaur } from './utilities/types';
import LoadingScreen from './views/loadingScreen';
import ErrorScreen from './views/errorScreen';
import DinoDataScreen from './views/dinoDataScreen';

function getRandomDinosaur() {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/dinosaur/random`);
}

function App() {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<null | string>(null);
	const [dinoData, setDinoData] = useState<IDinosaur>();

	useEffect(() => {
		getRandomDinosaur().then(function (dino) {
			// handle success
			setTimeout(() => {
				setDinoData(dino.data);
				setLoading(false);
			}, 3500);
		}).catch(function (error) {
			// handle error
			setError(error);
			setLoading(false);
		});
	}, []);

	return (
		<div className="@container w-screen h-screen overflow-hidden">
			{loading && <LoadingScreen />}
			{dinoData && <DinoDataScreen dinoData={{...dinoData}} />}
			{error && <ErrorScreen error={error} />}
		</div>
	);
}

export default App;
