import React, { ReactNode } from "react";

export type CardProps = {
	className?: string;
	bgClass: string;
	content: ReactNode;
};

const Card: React.FC<CardProps> = ({ className, bgClass, content }) => {
	return (
		<div className={`w-full flex justify-center items-center rounded-2xl mb-4 drop-shadow-md ${bgClass} ${className}`}>
			{content}
		</div>
	);
}

export default Card;